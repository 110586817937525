import each from 'lodash/each'
import i18next from 'i18next'
import isEmpty from 'lodash/isEmpty'
import { FormErrors } from 'redux-form'

// utils
import { CHALLENGE_TYPE, LANGUAGE, LANGUAGES, slugErrorText } from '../../../utils/enums'
import { slugRegex, urlRegEx } from '../../../utils/regex'

export type ChallengeFormValues = {
	id: number
	challengeType: CHALLENGE_TYPE
	allowedPlatforms: number[]
	name_sk: string
	name_cz?: string
	urlSlug_sk: string
	urlSlug_cz?: string
	briefDescription_sk: string
	briefDescription_cz?: string
	detailedDescription_sk: string
	detailedDescription_cz?: string
	videoPreviewID_sk: string
	videoPreviewID_cz?: string
	logo_sk: string
	logo_cz?: string
	image_sk: string
	image_cz?: string
	domain_sk: string
	domain_cz?: string
	startedAt: Date
	finishedAt: Date
	visibleFrom: Date
	visibleTo: Date
	signupDeadline: Date
	finishMeasurementsDeadline: Date
	backgroundColor: string
	order: number
	recommendations: number[]
	isPublished?: boolean
	publishDate?: Date
	exercises?: {
		id: number
		order: number | null
		isVisibleOnLandingPage: boolean
		isPublished: boolean
		name: string
	}[][]
	hardExercises?: {
		id: number
		order: number | null
		isVisibleOnLandingPage: boolean
		isPublished: boolean
		name: string
	}[][]
	exercisesOrder?: number[]
	hardExercisesOrder?: number[]
}

export default (values: ChallengeFormValues) => {
	const errors: FormErrors<ChallengeFormValues> = {}
	const exercisesErrors: any = []
	const hardExercisesErrors: any = []

	if (!values.challengeType) {
		errors.challengeType = i18next.t('Toto pole je povinné')
	}

	if (!values.allowedPlatforms || isEmpty(values.allowedPlatforms)) {
		errors.allowedPlatforms = i18next.t('Toto pole je povinné')
	}

	each(LANGUAGES, (item) => {
		if (item === LANGUAGE.SK || item === LANGUAGE.CZ) {
			if (!values[`name_${item}`]) {
				errors[`name_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (!values[`urlSlug_${item}`]) {
				errors[`urlSlug_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (values[`urlSlug_${item}`] && !slugRegex.test(values[`urlSlug_${item}`] as string)) {
				errors[`urlSlug_${item}`] = i18next.t(slugErrorText)
			}

			if (!values[`briefDescription_${item}`]) {
				errors[`briefDescription_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (!values[`detailedDescription_${item}`]) {
				errors[`detailedDescription_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (!values[`videoPreviewID_${item}`] || values[`videoPreviewID_${item}`]?.length === 0) {
				errors[`videoPreviewID_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (!values[`logo_${item}`]) {
				errors[`logo_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (!values[`image_${item}`]) {
				errors[`image_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (!values[`domain_${item}`]) {
				errors[`domain_${item}`] = i18next.t('Toto pole je povinné')
			}
			if (values[`domain_${item}`] && !urlRegEx.test(values[`domain_${item}`] as string)) {
				errors[`domain_${item}`] = i18next.t('Neplatná adresa')
			}
		}
	})

	if (!values.startedAt) {
		errors.startedAt = i18next.t('Toto pole je povinné')
	}

	if (!values.finishedAt) {
		errors.finishedAt = i18next.t('Toto pole je povinné')
	}

	if (!values.visibleFrom) {
		errors.visibleFrom = i18next.t('Toto pole je povinné')
	}

	if (!values.visibleTo) {
		errors.visibleTo = i18next.t('Toto pole je povinné')
	}

	if (values.visibleTo && values.visibleFrom && values.visibleTo?.valueOf() <= values.visibleFrom?.valueOf()) {
		errors.visibleTo = i18next.t('Dátum konca zobrazovania výzvy musí byť neskôr ako dátum začiatku')
		errors.visibleFrom = i18next.t('Dátum začiatku zobrazovania výzvy musí byť skôr ako dátum konca')
	}

	if (!values.signupDeadline) {
		errors.signupDeadline = i18next.t('Toto pole je povinné')
	}

	if (!values.finishMeasurementsDeadline) {
		errors.finishMeasurementsDeadline = i18next.t('Toto pole je povinné')
	}

	if (values.startedAt && values.finishedAt && values.startedAt?.valueOf() >= values.finishedAt?.valueOf()) {
		errors.startedAt = i18next.t('Dátum začiatku výzvy musí byť skôr ako dátum konca')
		errors.finishedAt = i18next.t('Dátum konca výzvy musí byť neskôr ako dátum začiatku')
	}

	if (values.finishMeasurementsDeadline && values.finishedAt && values.finishMeasurementsDeadline?.valueOf() <= values.finishedAt?.valueOf()) {
		errors.finishMeasurementsDeadline = i18next.t('Dátum konca zadávania mier musí byť neskôr ako dátum konca výzvy')
	}

	if (
		values.signupDeadline &&
		values.startedAt &&
		values.finishedAt &&
		(values.signupDeadline?.valueOf() < values.startedAt?.valueOf() || values.signupDeadline?.valueOf() > values.finishedAt?.valueOf())
	) {
		errors.signupDeadline = i18next.t('Dátum konca prihlasovania musí byť medzi začiatkom a koncom výzvy')
	}

	if (!values.backgroundColor) {
		errors.backgroundColor = i18next.t('Toto pole je povinné')
	}

	if (values.order && values.order < 1) {
		errors.order = i18next.t('Minimálna hodnota poradia je 1')
	}

	if ((values.recommendations || []).length > 3) {
		errors.recommendations = i18next.t('Vyber maximálne 3 doporučné programy po výzve')
	}

	if (values?.exercises) {
		values.exercises.forEach((day, index) => {
			if (isEmpty(day)) {
				exercisesErrors[index] = i18next.t('Deň musí obsahovať aspoň jedno cvičenie')
			}
		})

		if (!isEmpty(exercisesErrors)) {
			errors.exercises = exercisesErrors
		}
	}

	if (values?.hardExercises) {
		values.hardExercises.forEach((day, index) => {
			if (isEmpty(day)) {
				hardExercisesErrors[index] = i18next.t('Deň musí obsahovať aspoň jedno náročnejšie cvičenie')
			}
		})

		if (!isEmpty(hardExercisesErrors)) {
			errors.hardExercises = hardExercisesErrors
		}
	}

	return errors
}
