import React, { ChangeEvent, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import slugify from 'slugify'
import toInteger from 'lodash/toInteger'
import { Button, Col, Collapse, Modal, Pagination, Row, Select, Tabs, Tooltip } from 'antd'
import { DeleteOutlined, EyeOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons'
import { change, Field, getFormSyncErrors, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { debounce, find, get, map } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'
import { deleteProgram } from '../../../redux/programs/actions'
import {
	getBlogPostList,
	getEshopProductList,
	getExerciseList,
	getMotivationAdviceList,
	getPreferenceList,
	getTrainerList,
	getVideosList
} from '../../../redux/lists/actions'

// types
import { ArrElement } from '../../../types/types'
import { GetProgramsIdPayload } from '../../../redux/programs/types'
import { IFieldLabels } from '../../../types/interfaces'

// utils
import { FORMS, LANGUAGE, LANGUAGES, PLATFORM_OPTIONS, PROGRAM_EXERCISE_DIFFICULTY, UPLOAD_CATEGORY } from '../../../utils/enums'
import { getErrorFieldsLabel, getFieldLabel, getLanguageName } from '../../../utils/helpers'
import { uploadFile } from '../../../utils/fileUploader'

// forms
import validateProgramForm, { ProgramFormValues } from './validateProgramForm'

// components
import DetailHeader from '../../../components/DetailHeader'

// atoms
import AsyncTransferField from '../../../atoms/form/AsyncTransferField'
import CircleColorField from '../../../atoms/form/CircleColorField'
import CustomOptionsSelectField from '../../../atoms/form/CustomOptionsSelectField'
import DateField from '../../../atoms/form/DateField'
import SwitchField from '../../../atoms/form/SwitchField'
import TextField from '../../../atoms/form/TextField'
import UploadInputField from '../../../atoms/form/UploadField'
import WYSIWYGEditor from '../../../atoms/form/WYSIWYGEditor'

const PAGE_SIZE = 15
const { TabPane } = Tabs
const { Option } = Select
const { Panel } = Collapse

type ProgramFormProps = {
	isCreate: boolean
}

const ProgramForm = ({ handleSubmit, invalid, pristine, isCreate = false }: ProgramFormProps & InjectedFormProps<{}, ProgramFormProps>) => {
	const { t } = useTranslation()
	const history = useHistory()
	const dispatch = useDispatch()

	// selectors
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.PROGRAM_FORM)(state)) as ProgramFormValues
	const formErrors = useSelector(getFormSyncErrors(FORMS.PROGRAM_FORM))
	const listContext = useSelector((state: RootState) => state.lists?.list?.context)

	const preferences = useSelector((state: RootState) => state.lists?.list?.preferences)
	const products = useSelector((state: RootState) => state.lists?.list.upsellProducts)
	const videos = useSelector((state: RootState) => state.lists?.list.videos)
	const motivationAdvices = useSelector((state: RootState) => state.lists?.list.motivationAdvices)
	const blogPosts = useSelector((state: RootState) => state.lists?.list.blogPosts)
	const exercises = useSelector((state: RootState) => state.lists?.list.exercises)
	const trainers = useSelector((state: RootState) => state.lists?.list.trainers)

	// states
	const [image, setImage] = useState({ isLoading: false })
	const [logo, setLogo] = useState({ isLoading: false })
	const [inverseLogo, setInverseLogo] = useState({ isLoading: false })
	const [whiteLogo, setWhiteLogo] = useState({ isLoading: false })
	const [selectedTab, setSelectedTab] = useState('sk')
	const [exercisesCurrentPage, setExercisesCurrentPage] = useState(1)
	const [hardExercisesCurrentPage, setHardExercisesCurrentPage] = useState(1)

	// constants
	const preferencesList = map(preferences, (item) => ({ id: item.id, title: item.title }))
	const motivationAdvicesList = map(motivationAdvices, (item) => ({ id: item.id, title: item.title }))
	const blogPostsList = map(blogPosts, (item) => ({ id: item.id, title: item.title }))
	const exercisesList = map(exercises, (item) => ({ id: item.id, title: item.title, isVisibleInCalendar: item.isVisibleInCalendar, program: item.program }))
	const trainersList = map(trainers, (item) => ({ id: item.id, title: item.title }))
	const paginatedExercises = fieldValues?.exercises?.slice((exercisesCurrentPage - 1) * PAGE_SIZE, exercisesCurrentPage * PAGE_SIZE)
	const paginatedHardExercises = fieldValues?.hardExercises?.slice((hardExercisesCurrentPage - 1) * PAGE_SIZE, hardExercisesCurrentPage * PAGE_SIZE)
	const fieldLabels: IFieldLabels = {
		name_sk: t('Názov'),
		urlSlug_sk: t('URL'),
		briefDescription_sk: t('Krátky popis'),
		detailedDescription_sk: t('Detailný popis'),
		logo_sk: t('Logo'),
		image_sk: t('Titulný obrázok'),
		videoPreviewID_sk: t('Náhľadové video'),
		domain_sk: t('Doména stránky'),
		name_cz: t('Názov [CZ]'),
		urlSlug_cz: t('URL [CZ]'),
		briefDescription_cz: t('Krátky popis [CZ]'),
		detailedDescription_cz: t('Detailný popis [CZ]'),
		logo_cz: t('Logo [CZ]'),
		image_cz: t('Titulný obrázok [CZ]'),
		videoPreviewID_cz: t('Náhľadové video [CZ]'),
		domain_cz: t('Doména stránky [CZ]'),
		challengeType: t('Typ výzvy'),
		startedAt: t('Začiatok výzvy'),
		finishedAt: t('Koniec výzvy'),
		visibleFrom: t('Začiatok zobrazovania výzvy'),
		visibleTo: t('Koniec zobrazovania výzvy'),
		signupDeadline: t('Koniec prihlasovania a zadávania mier na začiatku výzvy'),
		finishMeasurementsDeadline: t('Koniec zadávania mier na konci výzvy'),
		backgroundColor: t('Farba banneru'),
		order: t('Poradie'),
		exercises: t('Cvičenia'),
		hardExercises: t('Náročnejšie cvičenia')
	}

	useEffect(() => {
		if ((fieldValues?.isPublished === true && fieldValues?.publishDate === null) || fieldValues?.publishDate === undefined) {
			dispatch(change(FORMS.PROGRAM_FORM, 'publishDate', dayjs()))
		}
	}, [fieldValues?.isPublished])

	const debounceChangeField = debounce((value, fieldName) => {
		const slug = slugify(value.toLowerCase(), {
			replacement: '-',
			remove: /[^A-Za-z0-9\s-]/g,
			lower: true
		})

		dispatch(change(FORMS.PROGRAM_FORM, fieldName, slug))
	}, 300)

	const changeUrlSlug = (event: any, language: LANGUAGE) => {
		if (isCreate) {
			debounceChangeField(event.target.value, `urlSlug_${language}`)
		}
	}

	const handleRemove = () => {
		Modal.confirm({
			title: t('Skutočne si prajete vymazať program?'),
			icon: <DeleteOutlined />,
			okText: t('Vymazať'),
			cancelText: t('Zrušiť'),
			okType: 'danger',
			onOk: () => {
				dispatch(
					deleteProgram(get(fieldValues, 'id'), () => {
						history.push(t('paths:programs|path'))
					})
				)
			}
		})
	}

	const uploadImage = async ({ file }: any, language: LANGUAGE) => {
		setImage({
			isLoading: true,
			[`imageUrl_${language}`]: ''
		})
		const fileUrl = await uploadFile(file, UPLOAD_CATEGORY.PROGRAM_COVER)

		if (fileUrl) {
			dispatch(change(FORMS.PROGRAM_FORM, `image_${language}`, fileUrl))
			setImage({
				isLoading: false,
				[`imageUrl_${language}`]: fileUrl
			})
		}
	}

	const uploadLogo = async ({ file }: any, language: LANGUAGE) => {
		setLogo({
			isLoading: true,
			[`imageUrl_${language}`]: ''
		})
		const fileUrl = await uploadFile(file, UPLOAD_CATEGORY.PROGRAM_LOGO)

		if (fileUrl) {
			dispatch(change(FORMS.PROGRAM_FORM, `logo_${language}`, fileUrl))
			setLogo({
				isLoading: false,
				[`imageUrl_${language}`]: fileUrl
			})
		}
	}

	const uploadInverseLogo = async ({ file }: any, language: LANGUAGE) => {
		setInverseLogo({
			isLoading: true,
			[`imageUrl_${language}`]: ''
		})
		const fileUrl = await uploadFile(file, UPLOAD_CATEGORY.PROGRAM_LOGO)

		if (fileUrl) {
			dispatch(change(FORMS.PROGRAM_FORM, `inverseLogo_${language}`, fileUrl))
			setInverseLogo({
				isLoading: false,
				[`imageUrl_${language}`]: fileUrl
			})
		}
	}

	const uploadWhiteLogo = async ({ file }: any, language: LANGUAGE) => {
		setWhiteLogo({
			isLoading: true,
			[`imageUrl_${language}`]: ''
		})
		const fileUrl = await uploadFile(file, UPLOAD_CATEGORY.PROGRAM_LOGO)

		if (fileUrl) {
			dispatch(change(FORMS.PROGRAM_FORM, `whiteLogo_${language}`, fileUrl))
			setWhiteLogo({
				isLoading: false,
				[`imageUrl_${language}`]: fileUrl
			})
		}
	}

	const removeWhiteLogo = (language: LANGUAGE) => {
		dispatch(change(FORMS.PROGRAM_FORM, `whiteLogo_${language}`, null))
	}

	const handleSwitchExercisesChange = (value: boolean, day: number, record: ArrElement<ArrElement<GetProgramsIdPayload['exercises']>>, attribute: any) => {
		if (fieldValues?.exercises) {
			let focusedRow = find(fieldValues.exercises[day], ['id', get(record, 'id')])
			const exerciseArray: any = []
			map(fieldValues.exercises[day], (item) => {
				if (item === focusedRow) {
					focusedRow = {
						...focusedRow,
						[attribute]: value
					}
					exerciseArray.push(focusedRow)
				} else {
					exerciseArray.push(item)
				}
			})

			dispatch(change(FORMS.PROGRAM_FORM, `exercises[${day}]`, exerciseArray))
		}
	}

	const handleSwitchHardExercisesChange = (
		value: boolean,
		day: number,
		record: ArrElement<ArrElement<GetProgramsIdPayload['hardExercises']>>,
		attribute: any
	) => {
		if (fieldValues?.hardExercises) {
			let focusedRow = find(fieldValues.hardExercises[day], ['id', record?.id])
			const hardExerciseArray: any = []
			map(fieldValues.hardExercises[day], (item) => {
				if (item === focusedRow) {
					focusedRow = {
						...focusedRow,
						[attribute]: value
					}
					hardExerciseArray.push(focusedRow)
				} else {
					hardExerciseArray.push(item)
				}
			})

			dispatch(change(FORMS.PROGRAM_FORM, `hardExercises[${day}]`, hardExerciseArray))
		}
	}

	const handleDeleteExercisesDay = (dayIndex: number) => {
		if (fieldValues?.exercises) {
			const selectedDay = fieldValues.exercises[dayIndex]
			const updatedItems = fieldValues.exercises.filter((_: any, i: number) => i !== dayIndex)

			if (isEmpty(selectedDay)) {
				dispatch(change(FORMS.PROGRAM_FORM, 'exercises', updatedItems))
				// NOTE: If the last day is deleted and there are no items in actual page, set the current page to the previous one
				if (updatedItems.length / PAGE_SIZE <= exercisesCurrentPage - 1) {
					setExercisesCurrentPage(exercisesCurrentPage - 1)
				}
			} else {
				Modal.confirm({
					title: t('Vymazanie dňa'),
					content: t('Zvolený deň obsahuje cvičenia, naozaj si ho prajete vymazať?'),
					okText: t('Áno'),
					cancelText: t('Nie'),
					onOk: () => {
						dispatch(change(FORMS.PROGRAM_FORM, 'exercises', updatedItems))
						// NOTE: If the last day is deleted and there are no items in actual page, set the current page to the previous one
						if (updatedItems.length / PAGE_SIZE <= exercisesCurrentPage - 1) {
							setExercisesCurrentPage(exercisesCurrentPage - 1)
						}
					}
				})
			}
		}
	}

	const handleDeleteHardExercisesDay = (dayIndex: number) => {
		if (fieldValues?.hardExercises) {
			const selectedDay = fieldValues.hardExercises[dayIndex]
			const updatedItems = fieldValues.hardExercises.filter((_: any, i: number) => i !== dayIndex)

			if (isEmpty(selectedDay)) {
				dispatch(change(FORMS.PROGRAM_FORM, 'hardExercises', updatedItems))
				// NOTE: If the last day is deleted and there are no items in actual page, set the current page to the previous one
				if (updatedItems.length / PAGE_SIZE <= hardExercisesCurrentPage - 1) {
					setHardExercisesCurrentPage(hardExercisesCurrentPage - 1)
				}
			} else {
				Modal.confirm({
					title: t('Vymazanie dňa'),
					content: t('Zvolený deň obsahuje cvičenia, naozaj si ho prajete vymazať?'),
					okText: t('Áno'),
					cancelText: t('Nie'),
					onOk: () => {
						dispatch(change(FORMS.PROGRAM_FORM, 'hardExercises', updatedItems))
						// NOTE: If the last day is deleted and there are no items in actual page, set the current page to the previous one
						if (updatedItems.length / PAGE_SIZE <= hardExercisesCurrentPage - 1) {
							setHardExercisesCurrentPage(hardExercisesCurrentPage - 1)
						}
					}
				})
			}
		}
	}

	const handlePageChange = (difficulty: PROGRAM_EXERCISE_DIFFICULTY, page: number) => {
		switch (difficulty) {
			case PROGRAM_EXERCISE_DIFFICULTY.NORMAL:
				setExercisesCurrentPage(page)
				break
			case PROGRAM_EXERCISE_DIFFICULTY.HARD:
				setHardExercisesCurrentPage(page)
				break
			default:
				break
		}
	}

	return (
		<form onSubmit={handleSubmit}>
			<Row gutter={16}>
				<Col span={18} className={'grid'}>
					<div className={'flex direction-col justify-start main-content'}>
						<DetailHeader
							title={isCreate ? t('Pridať program') : t('Detail programu')}
							detailButtons={
								!isCreate && [
									{
										title: t('Pridať nový program'),
										path: t('paths:programCreate|path')
									},
									{
										title: t('Pridať novú výzvu'),
										path: t('paths:challengeCreate|path')
									}
								]
							}
						/>
						<Field
							name={'allowedPlatforms'}
							component={CustomOptionsSelectField}
							label={t('Platformy (na ktorých sa zobrazuje)')}
							filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							options={map(PLATFORM_OPTIONS, (part, index) => (
								<Option key={index} value={get(part, 'value')}>
									{get(part, 'label') || ''}
								</Option>
							))}
							multiple
							required
						/>
						<Tabs defaultActiveKey={LANGUAGE.SK} className={'translations'} onTabClick={(key) => setSelectedTab(key)}>
							{map(LANGUAGES, (item) => (
								<TabPane tab={getLanguageName(item)} key={item}>
									<Field
										name={`name_${item}`}
										component={TextField}
										label={t('Názov')}
										onInput={(e: any) => changeUrlSlug(e, item)}
										size={'large'}
										required
									/>
									<Row>
										<Col span={6}>
											<Field
												name={`image_${item}`}
												imageUrl={get(fieldValues, `image_${item}`)}
												component={UploadInputField}
												label={t('Titulný obrázok (upload)')}
												customHelper={t('recommendedSize|videoThumbnail')}
												customRequest={(file: any) => uploadImage(file, item)}
												isLoading={image.isLoading}
												required
											/>
										</Col>
										<Col span={6}>
											<Field
												name={`logo_${item}`}
												imageUrl={get(fieldValues, `logo_${item}`)}
												component={UploadInputField}
												label={t('Logo (upload)')}
												customRequest={(file: any) => uploadLogo(file, item)}
												customHelper={t('recommendedSize|programLogo')}
												isLoading={logo.isLoading}
												required
											/>
										</Col>
										<Col span={6}>
											<Field
												name={`inverseLogo_${item}`}
												imageUrl={get(fieldValues, `inverseLogo_${item}`)}
												component={UploadInputField}
												label={t('Inverzne logo (upload)')}
												customClass={'logo-uploader-dark'}
												customHelper={t('recommendedSize|programLogo')}
												customRequest={(file: any) => uploadInverseLogo(file, item)}
												isLoading={inverseLogo.isLoading}
												required
											/>
										</Col>
										<Col span={6}>
											<Field
												name={`whiteLogo_${item}`}
												imageUrl={get(fieldValues, `whiteLogo_${item}`)}
												component={UploadInputField}
												label={t('Biele logo (upload)')}
												customClass={'logo-uploader-dark'}
												customHelper={t('recommendedSize|programLogo')}
												customRequest={(file: any) => uploadWhiteLogo(file, item)}
												isLoading={whiteLogo.isLoading}
												onRemove={() => removeWhiteLogo(item)}
											/>
										</Col>
									</Row>

									<Field name={`urlSlug_${item}`} component={TextField} label={t('URL')} required />

									<Field name={`briefDescription_${item}`} component={TextField} label={t('Krátky popis')} required />

									<Field name={`detailedDescription_${item}`} component={WYSIWYGEditor} label={t('Detailný popis')} required />

									{!fieldValues?.isEducational && (
										<Field
											name={`videoPreviewID_${item}`}
											component={AsyncTransferField}
											label={t('Náhľadové video (link na Vimeo)')}
											customButton={{
												icon: <EyeOutlined />,
												handler: (id: number) => {
													window.open(`${t('paths:videoDefault|path')}/detail/${id}`, '_blank')
												}
											}}
											required
											reduxFetch={(config: any) => dispatch(getVideosList(config))}
											modalDataSource={videos}
											showPagination={false}
											context={listContext}
											maxItems={1}
										/>
									)}

									<Field name={`stickerText_${item}`} component={TextField} label={t('Text nálepky')} />

									<Field name={`stickerColor_${item}`} component={CircleColorField} label={t('Farba nálepky')} />
								</TabPane>
							))}
						</Tabs>
						<Field
							name={'preferences'}
							component={AsyncTransferField}
							label={t('Preferencie')}
							reduxFetch={(config: any) => dispatch(getPreferenceList(config))}
							modalDataSource={preferencesList}
							context={listContext}
						/>
						<Field
							name={'recommendedBlogPosts'}
							component={AsyncTransferField}
							label={t('Odporúčané blogové články')}
							customButton={{
								icon: <EyeOutlined />,
								handler: (id: number) => {
									window.open(`${t('paths:blogDetail|path')}/${id}`, '_blank')
								}
							}}
							reduxFetch={(config: any) => dispatch(getBlogPostList(config))}
							modalDataSource={blogPostsList}
							context={listContext}
						/>
						<Field
							name={'motivationAdvices'}
							component={AsyncTransferField}
							label={t('Motivačné tipy')}
							customButton={{
								icon: <EyeOutlined />,
								handler: (id: number) => {
									window.open(`${t('paths:motivationAdvice|path')}/${id}`, '_blank')
								}
							}}
							showPagination={false} // sortable
							reduxFetch={(config: any) => dispatch(getMotivationAdviceList(config))}
							modalDataSource={motivationAdvicesList}
							context={listContext}
						/>

						<Collapse className={'program-exercises-collapse'}>
							<Panel key={0} header={fieldValues?.isRestricted ? t('Videá') : t('Cvičenia')}>
								{!isEmpty(fieldValues?.exercises) && (
									<Row style={{ marginBottom: '8px' }}>
										<Col span={3}>
											<strong>{t('Poradie')}</strong>
										</Col>
									</Row>
								)}
								{paginatedExercises?.map((_, index) => {
									const actualIndex = (exercisesCurrentPage - 1) * PAGE_SIZE + index
									return (
										<>
											<Row gutter={8} align={'top'} justify={'space-between'} wrap={false}>
												<Col span={3}>
													<Field
														name={`exercisesOrder[${actualIndex}]`}
														component={TextField}
														customInputClass={'exercise-order'}
														type={'number'}
														min={1}
														value={toInteger(fieldValues?.exercisesOrder?.[actualIndex])}
														normalize={(value: string) => (value ? toInteger(value) : value)}
													/>
												</Col>
												<Col flex={'auto'}>
													<Field
														name={`exercises[${actualIndex}]`}
														component={AsyncTransferField}
														label={t(`Deň ${actualIndex + 1}`)}
														reduxFetch={(config: any) => dispatch(getExerciseList(config))}
														modalDataSource={exercisesList}
														context={listContext}
														customButton={{
															icon: <EyeOutlined />,
															handler: (id: number) => {
																window.open(`${t('paths:exercise|path')}/${id}`, '_blank')
															}
														}}
														showPagination={false} // sortable
														includeSwitcher
														includeDifficultySwitcher
														onSwitchChange={(value: boolean, record: ArrElement<ArrElement<GetProgramsIdPayload['exercises']>>) =>
															handleSwitchExercisesChange(value, actualIndex, record, 'isVisibleInCalendar')
														}
														onDifficultySwitchChange={(
															value: boolean,
															record: ArrElement<ArrElement<GetProgramsIdPayload['exercises']>>
														) => handleSwitchExercisesChange(value, actualIndex, record, 'isDifficultyRatingVisible')}
													/>
												</Col>
												<Col>
													<Tooltip title={t('Vymazať deň')}>
														<Button
															icon={<DeleteOutlined />}
															type={'primary'}
															size={'large'}
															danger={true}
															onClick={() => handleDeleteExercisesDay(actualIndex)}
															style={{ width: '46px', height: '46px' }}
														/>
													</Tooltip>
												</Col>
											</Row>
											<br />
										</>
									)
								})}
								{!isEmpty(fieldValues?.exercises) && (
									<Pagination
										current={exercisesCurrentPage}
										pageSize={PAGE_SIZE}
										total={fieldValues?.exercises?.length || 0}
										onChange={(page) => handlePageChange(PROGRAM_EXERCISE_DIFFICULTY.NORMAL, page)}
										showSizeChanger={false}
										style={{ textAlign: 'center' }}
									/>
								)}
								<Row gutter={16} style={{ marginTop: '8px' }}>
									<Col>
										<Tooltip
											title={
												!isEmpty(fieldValues?.exercises) && isEmpty(last(fieldValues?.exercises))
													? t('Pred pridaním ďalšieho dňa je potrebné vyplniť posledný deň')
													: null
											}
										>
											<Button
												type={'primary'}
												icon={<PlusOutlined />}
												onClick={() => {
													const updatedExercises = fieldValues?.exercises ? [...fieldValues.exercises, []] : [[]]
													const updatedExercisesOrder = fieldValues?.exercisesOrder
														? [...fieldValues.exercisesOrder, fieldValues.exercisesOrder.length + 1]
														: [1]
													dispatch(change(FORMS.PROGRAM_FORM, 'exercises', updatedExercises))
													dispatch(change(FORMS.PROGRAM_FORM, 'exercisesOrder', updatedExercisesOrder))

													// Note: Set current page to the last page after adding a new day
													const lastPage = Math.ceil(updatedExercises.length / PAGE_SIZE)
													setExercisesCurrentPage(lastPage)
												}}
												disabled={!isEmpty(fieldValues?.exercises) && isEmpty(last(fieldValues?.exercises))}
											>
												{t('Pridať deň')}
											</Button>
										</Tooltip>
									</Col>
									{!isEmpty(fieldValues?.exercises) && (
										<Col>
											<Button
												type='primary'
												icon={<SaveOutlined />}
												onClick={() => {
													if (!fieldValues?.exercises || !fieldValues?.exercisesOrder) return

													// Create array of exercises with their original indices
													const exercisesWithOrder = fieldValues.exercises.map((day, index) => ({
														day,
														order: fieldValues?.exercisesOrder?.[index] || index + 1,
														originalIndex: index
													}))

													// Sort exercises based on order and original index
													exercisesWithOrder.sort((a, b) => {
														// If orders are different, sort by order
														if (a.order !== b.order) {
															return (a.order || 0) - (b.order || 0)
														}
														// If orders are the same, new item goes before the existing one
														return b.originalIndex - a.originalIndex
													})

													// Extract sorted exercises and create new order array
													const newExercises = exercisesWithOrder.map((item) => item.day)
													const newOrder = exercisesWithOrder.map((_, index) => index + 1)

													// Update both exercises and their order
													dispatch(change(FORMS.PROGRAM_FORM, 'exercises', newExercises))
													dispatch(change(FORMS.PROGRAM_FORM, 'exercisesOrder', newOrder))
												}}
											>
												{t('Uložiť poradie')}
											</Button>
										</Col>
									)}
								</Row>
							</Panel>
						</Collapse>

						{!fieldValues?.isRestricted && (
							<Collapse className={'program-exercises-collapse'}>
								<Panel key={0} header={t('Náročnejšie cvičenia')}>
									{!isEmpty(fieldValues?.hardExercises) && (
										<Row style={{ marginBottom: '8px' }}>
											<Col span={3}>
												<strong>{t('Poradie')}</strong>
											</Col>
										</Row>
									)}
									{paginatedHardExercises?.map((_, index) => {
										const actualIndex = (hardExercisesCurrentPage - 1) * PAGE_SIZE + index
										return (
											<>
												<Row gutter={8} align={'top'} justify={'space-between'} wrap={false}>
													<Col span={3}>
														<Field
															name={`hardExercisesOrder[${actualIndex}]`}
															component={TextField}
															customInputClass={'exercise-order'}
															type={'number'}
															min={1}
															value={toInteger(fieldValues?.hardExercisesOrder?.[actualIndex])}
															normalize={(value: string) => (value ? toInteger(value) : value)}
														/>
													</Col>
													<Col flex={'auto'}>
														<Field
															name={`hardExercises[${actualIndex}]`}
															component={AsyncTransferField}
															label={t(`Deň ${actualIndex + 1}`)}
															reduxFetch={(config: any) => dispatch(getExerciseList(config))}
															modalDataSource={exercisesList}
															context={listContext}
															customButton={{
																icon: <EyeOutlined />,
																handler: (id: number) => window.open(`${t('paths:exercise|path')}/${id}`, '_blank')
															}}
															showPagination={false} // sortable
															includeSwitcher
															includeDifficultySwitcher
															onSwitchChange={(
																value: boolean,
																record: ArrElement<ArrElement<GetProgramsIdPayload['hardExercises']>>
															) => handleSwitchHardExercisesChange(value, actualIndex, record, 'isVisibleInCalendar')}
															onDifficultySwitchChange={(
																value: boolean,
																record: ArrElement<ArrElement<GetProgramsIdPayload['hardExercises']>>
															) => handleSwitchHardExercisesChange(value, actualIndex, record, 'isDifficultyRatingVisible')}
														/>
													</Col>
													<Col>
														<Tooltip title={t('Vymazať deň')}>
															<Button
																icon={<DeleteOutlined />}
																type={'primary'}
																size={'large'}
																danger={true}
																onClick={() => handleDeleteHardExercisesDay(actualIndex)}
																style={{ width: '46px', height: '46px' }}
															/>
														</Tooltip>
													</Col>
												</Row>
												<br />
											</>
										)
									})}
									{!isEmpty(fieldValues?.hardExercises) && (
										<Pagination
											current={hardExercisesCurrentPage}
											pageSize={PAGE_SIZE}
											total={fieldValues?.hardExercises?.length || 0}
											onChange={(page) => handlePageChange(PROGRAM_EXERCISE_DIFFICULTY.HARD, page)}
											showSizeChanger={false}
											style={{ textAlign: 'center' }}
										/>
									)}
									<Row gutter={16} style={{ marginTop: '8px' }}>
										<Col>
											<Tooltip
												title={
													!isEmpty(fieldValues?.hardExercises) && isEmpty(last(fieldValues?.hardExercises))
														? t('Pred pridaním ďalšieho dňa je potrebné vyplniť posledný deň')
														: null
												}
											>
												<Button
													type={'primary'}
													icon={<PlusOutlined />}
													onClick={() => {
														const updatedHardExercises = fieldValues?.hardExercises ? [...fieldValues.hardExercises, []] : [[]]
														const updatedHardExercisesOrder = fieldValues?.hardExercisesOrder
															? [...fieldValues.hardExercisesOrder, fieldValues.hardExercisesOrder.length + 1]
															: [1]
														dispatch(change(FORMS.PROGRAM_FORM, 'hardExercises', updatedHardExercises))
														dispatch(change(FORMS.PROGRAM_FORM, 'hardExercisesOrder', updatedHardExercisesOrder))
														// Note: Set current page to the last page after adding a new day
														const lastPage = Math.ceil(updatedHardExercises.length / PAGE_SIZE)
														setHardExercisesCurrentPage(lastPage)
													}}
													disabled={!isEmpty(fieldValues?.hardExercises) && isEmpty(last(fieldValues?.hardExercises))}
												>
													{t('Pridať deň')}
												</Button>
											</Tooltip>
										</Col>
										{!isEmpty(fieldValues?.hardExercises) && (
											<Col>
												<Button
													type='primary'
													icon={<SaveOutlined />}
													onClick={() => {
														if (!fieldValues?.hardExercises || !fieldValues?.hardExercisesOrder) return

														// Create array of exercises with their original indices
														const hardExercisesWithOrder = fieldValues.hardExercises.map((day, index) => ({
															day,
															order: fieldValues?.hardExercisesOrder?.[index] || index + 1,
															originalIndex: index
														}))

														// Sort exercises based on order and original index
														hardExercisesWithOrder.sort((a, b) => {
															// If orders are different, sort by order
															if (a.order !== b.order) {
																return (a.order || 0) - (b.order || 0)
															}
															// If orders are the same, new item goes before the existing one
															return b.originalIndex - a.originalIndex
														})

														// Extract sorted exercises and create new order array
														const newHardExercises = hardExercisesWithOrder.map((item) => item.day)
														const newOrder = hardExercisesWithOrder.map((_, index) => index + 1)

														// Update both exercises and their order
														dispatch(change(FORMS.PROGRAM_FORM, 'hardExercises', newHardExercises))
														dispatch(change(FORMS.PROGRAM_FORM, 'hardExercisesOrder', newOrder))
													}}
												>
													{t('Uložiť poradie')}
												</Button>
											</Col>
										)}
									</Row>
								</Panel>
							</Collapse>
						)}

						<Field
							name={'trainers'}
							component={AsyncTransferField}
							label={t('Inštruktori')}
							reduxFetch={(config: any) => dispatch(getTrainerList(config))}
							customButton={{
								icon: <EyeOutlined />,
								handler: (id: number) => {
									window.open(`${t('paths:instructor|path')}/${id}`, '_blank')
								}
							}}
							modalDataSource={trainersList}
							context={listContext}
						/>
						<Field
							name={'upsellProducts'}
							component={AsyncTransferField}
							label={t('Upsell produkty')}
							reduxFetch={(config: any) => dispatch(getEshopProductList(config))}
							modalDataSource={products}
							context={listContext}
						/>
					</div>
				</Col>
				<Col span={6} className={'grid'}>
					<div className={'flex direction-col justify-start sidebar-content'}>
						<Field name={'order'} component={TextField} label={t('Poradie')} type={'number'} min={1} />
						{!isCreate && <Field name={'updatedAt'} component={TextField} disabled label={t('Dátum poslednej úpravy')} />}
						<Field name={'publishDate'} component={DateField} label={t('Dátum a čas publikovania')} dttmFormat={'D.M.YYYY HH:mm'} showTime />
						<Field name={'isPublished'} component={SwitchField} label={t('Publikované')} />
						<Field
							name={'isEducational'}
							component={SwitchField}
							label={t('Edukačný program')}
							onChange={(value: ChangeEvent<HTMLInputElement>) => {
								if (value) {
									dispatch(change(FORMS.PROGRAM_FORM, 'isRestricted', true))
								}
							}}
						/>
						<Field name={'isRestricted'} component={SwitchField} label={t('S obmedzením')} disabled={fieldValues?.isEducational} />
						<Field name={'isPregnantSuitable'} component={SwitchField} label={t('Vhodné pre tehotné')} />
						<Field name={'isVisibleOnLandingPage'} component={SwitchField} label={t('Viditeľné na landing page')} />
						<Field name={'isLiveStreamCategory'} component={SwitchField} label={t('Kategoria živého prenosu')} />
						<Field name={'showExercisesInLists'} component={SwitchField} label={t('Zobraziť v zozname cvičení')} />
						<div className={'flex direction-col justify-center'}>
							<Tooltip
								title={
									invalid
										? `${t('Pred odoslaním treba správne vyplniť')}: ${getErrorFieldsLabel(formErrors, (field) =>
												getFieldLabel(field, fieldLabels)
											)}`
										: null
								}
								placement={'bottom'}
							>
								<Button
									icon={<SaveOutlined />}
									onClick={handleSubmit}
									disabled={pristine || invalid}
									type={'primary'}
									style={{ margin: '20px' }}
								>
									{t('Uložiť')}
								</Button>
							</Tooltip>

							{!isCreate && (
								<Button
									icon={<EyeOutlined />}
									onClick={() => {
										if (fieldValues?.isPublished) {
											window.open(
												`${window.__RUNTIME_CONFIG__.REACT_APP_FRONTEND_URL}${t('paths:programs|path')}/${get(
													fieldValues,
													`urlSlug_${selectedTab}`
												)}`,
												'_blank'
											)
										} else {
											window.open(
												`${window.__RUNTIME_CONFIG__.REACT_APP_FRONTEND_URL}${t('paths:programPreview|path')}/${get(
													fieldValues,
													'id'
												)}`,
												'_blank'
											)
										}
									}}
									type={'default'}
									style={{ margin: '20px' }}
								>
									{t('Zobraziť na webe')}
								</Button>
							)}

							{!isCreate && (
								<Button icon={<DeleteOutlined />} onClick={() => handleRemove()} type={'primary'} danger style={{ margin: '20px' }}>
									{t('Vymazať program')}
								</Button>
							)}
						</div>
					</div>
				</Col>
			</Row>
		</form>
	)
}

export default reduxForm<{}, ProgramFormProps>({
	form: FORMS.PROGRAM_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateProgramForm
})(ProgramForm)
