import { useCallback, useEffect } from 'react'
import dayjs from 'dayjs'
import each from 'lodash/each'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { Spin } from 'antd'
import { initialize } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { createExercise, getExercise, updateExercise } from '../../redux/exercises/actions'

// utils
import { FORMS, LANGUAGE, LANGUAGES } from '../../utils/enums'

// types
import { GetExercisesIdPayload, PostExercisesBody, PutExercisesIdBody } from '../../redux/exercises/types'
import { IComputedMatch } from '../../types/interfaces'

// forms
import { ExerciseFormValues } from './forms/validateExerciseForm'
import ExerciseForm from './forms/ExerciseForm'

type DetailExerciseProps = {
	computedMatch: IComputedMatch
}

const DetailExercise = ({ computedMatch }: DetailExerciseProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()
	const { id } = computedMatch.params
	const { isArray } = Array

	// selectors
	const detail = useSelector((state: RootState) => state.exercises?.detail)

	// constants
	const isLoading = detail?.isLoading

	const getValueObject = (value: any, attName: string) => {
		if (value === null || isEmpty(value)) return null
		if (isArray(value)) {
			return [{ id: value[0].id, title: value[0]?.[`${attName}`] }]
		}
		return [{ id: value.id, title: value?.[`${attName}`] }]
	}

	const initEmptyDetailForm = useCallback(() => {
		const initValues = {
			bodyParts: [],
			accessories: [],
			order: 1
		}

		dispatch(initialize(FORMS.EXERCISE_FORM, initValues))
	}, [dispatch])

	const initDetailForm = useCallback(
		(data: GetExercisesIdPayload) => {
			const exerciseData = data?.exercise
			const langData = exerciseData?.translations
			const langValues: any = {}

			const initValues = {
				id: get(exerciseData, 'id'),
				video:
					get(exerciseData, 'video') === null
						? []
						: [
								{
									id: get(exerciseData, 'video.id'),
									title: get(exerciseData, 'video.translations[0].name', '---'),
									urlSlug: get(exerciseData, 'video.translations[0].urlSlug', '---')
								}
							],
				difficulty: exerciseData?.difficulty,
				mediumDifficulty: exerciseData?.mediumDifficulty,
				programs: [
					{
						id: get(exerciseData, 'program.id'),
						title: get(exerciseData, 'program.name', '---'),
						urlSlug: get(exerciseData, 'program.urlSlug')
					}
				],
				image: get(exerciseData, 'image'),
				bonusExercises: map(get(exerciseData, 'bonusExercises'), (item, index) => ({
					key: index,
					id: get(item, 'id'),
					title: get(item, 'translations.0.name'),
					labelSK: find(item?.bonusExerciseTranslations, ['language', LANGUAGE.SK])?.label,
					labelCZ: find(item?.bonusExerciseTranslations, ['language', LANGUAGE.CZ])?.label
				})),
				order: get(exerciseData, 'order') || 1,
				publishDate: get(exerciseData, 'publishDate'),
				isPublished: get(exerciseData, 'isPublished'),
				isLiveStream: get(exerciseData, 'isLiveStream'),
				liveVideoID:
					get(exerciseData, 'liveStream') !== null
						? [
								{
									id: get(exerciseData, 'liveStream.id'),
									title: get(exerciseData, 'liveStream.name')
								}
							]
						: [],
				liveStreamPublishDate: get(exerciseData, 'liveStream.publishDate') ? dayjs(get(exerciseData, 'liveStream.publishDate')) : dayjs(),
				stream: get(exerciseData, 'stream'),
				updatedAt: dayjs(exerciseData?.updatedAt).format('H:mm:ss D. MMM YYYY '),
				easyExerciseVariant: getValueObject(get(exerciseData, 'easyExerciseVariant', []), 'name'),
				hardExerciseVariant: getValueObject(get(exerciseData, 'hardExerciseVariant', []), 'name'),
				motivationAdvices: getValueObject(get(exerciseData, 'motivationAdvice', []), 'name'),
				recommendedExercises: map(get(exerciseData, 'recommendedExercises'), (item) => ({ id: item.id, title: item.name })),
				recommendedBlogPosts: map(get(exerciseData, 'recommendedBlogPosts'), (item) => ({ id: item.id, title: item.title })),
				upsellProducts: map(get(exerciseData, 'upsellProducts'), (item: any) => ({ id: item.id, title: item.translations[0].name })),
				trainers: map(get(exerciseData, 'trainers'), (item) => ({ id: item.id, title: item.name }))
			}

			forEach(LANGUAGES, (item) => {
				const lang = find(langData, { language: item })
				langValues[`name_${item}`] = get(lang, 'name')
				langValues[`urlSlug_${item}`] = get(lang, 'urlSlug')
				langValues[`detailedDescription_${item}`] = get(lang, 'detailedDescription')
			})

			dispatch(
				initialize(FORMS.EXERCISE_FORM, {
					...initValues,
					...langValues
				})
			)
		},
		[dispatch]
	)

	useEffect(() => {
		if (id) {
			dispatch(getExercise(id, (data: GetExercisesIdPayload) => initDetailForm(data)))
		} else {
			initEmptyDetailForm()
		}
	}, [computedMatch, id, initDetailForm, initEmptyDetailForm])

	const handleCreate = (body: PostExercisesBody) => dispatch(createExercise(body, (exerciseID) => history.push(`${t('paths:exercise|path')}/${exerciseID}`)))

	const handleUpdate = (body: PutExercisesIdBody) => dispatch(updateExercise(id, body, () => dispatch(getExercise(id, (data) => initDetailForm(data)))))

	const handleSubmit = (values: ExerciseFormValues) => {
		const body: any = {
			translations: [],
			videoID: get(values, 'video[0].id', null) || null,
			programID: get(values, 'programs[0].id'),
			order: get(values, 'order'),
			image: get(values, 'image'),
			publishDate: get(values, 'publishDate', dayjs()),
			isPublished: get(values, 'isPublished'),
			liveStream:
				get(values, 'isLiveStream') === true
					? {
							liveVideoID: get(values, 'liveVideoID[0].id'),
							liveStreamPublishDate: dayjs(get(values, 'liveStreamPublishDate'))
						}
					: undefined,
			easyExerciseVariant: get(values, 'easyExerciseVariant[0].id'),
			hardExerciseVariant: get(values, 'hardExerciseVariant[0].id'),
			motivationAdvice: get(values, 'motivationAdvices[0].id'),
			bonusExercises: map(get(values, 'bonusExercises'), (item) => ({
				id: get(item, 'id'),
				title: get(item, 'translations.0.name'),
				translations: [
					{
						language: LANGUAGE.SK,
						label: get(item, 'labelSK')
					},
					{
						language: LANGUAGE.CZ,
						label: get(item, 'labelCZ')
					}
				]
			})),
			upsellProducts: map(get(values, 'upsellProducts'), (item: any) => item.id),
			recommendedExercises: map(get(values, 'recommendedExercises'), (item: any) => item.id),
			recomendedExerciseBlogPosts: map(get(values, 'recommendedBlogPosts'), (item: any) => item.id)
		}

		each(LANGUAGES, (item) => {
			const detailedDescription = get(values, `detailedDescription_${item}`)

			if (detailedDescription) {
				const translation = {
					urlSlug: get(values, `urlSlug_${item}`),
					language: item,
					name: get(values, `name_${item}`),
					detailedDescription: get(values, `detailedDescription_${item}`)
				}

				body.translations.push(translation)
			}
		})

		if (id) {
			handleUpdate(body)
		} else {
			handleCreate(body)
		}
	}

	return (
		<div className={'page-wrapper'}>
			<Spin spinning={isLoading}>
				<ExerciseForm onSubmit={handleSubmit} isCreate={!id} isPublished={detail?.data?.exercise?.isPublished} />
			</Spin>
		</div>
	)
}

export default DetailExercise
