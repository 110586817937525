import mapValues from 'lodash/mapValues'

// utils
import { ENDPOINTS } from '../../utils/enums'
import { getReq } from '../../utils/request'

// types
import * as ACTIONS from './types'

interface IParams {
	search?: string
	page?: number
	limit?: number
	showRestricted?: boolean
}

const getList = (endpoint: string, params?: IParams) => async (dispatch: any) => {
	dispatch({ type: ACTIONS.GET_LIST.START })
	try {
		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => (query === '' ? undefined : query))
		const { data } = await getReq(endpoint, normalizeQueryParams)

		dispatch({ type: ACTIONS.GET_LIST.DONE, payload: data })
		return true
	} catch (error) {
		dispatch({ type: ACTIONS.GET_LIST.FAILED })
		return false
	}
}

export const getExerciseList = (params?: IParams) => getList(ENDPOINTS.LIST_GET_EXERCISES, params)
export const getTargetsList = (params?: IParams) => getList(ENDPOINTS.LIST_GET_TARGETS, params)
export const getMotivationAdviceList = (params?: IParams) => getList(ENDPOINTS.LIST_GET_MOTIVATION_ADVICE, params)
export const getAccessorieList = (params?: IParams) => getList(ENDPOINTS.LIST_GET_ACCESSORIES, params)
export const getLivestreamCategoriesList = (params?: IParams) => getList(ENDPOINTS.LIST_GET_LIVESTREAM_CATEGORIES, params)
export const getTrainerList = (params?: IParams) => getList(ENDPOINTS.LIST_GET_TRAINERS, params)
export const getProgramList = (params?: IParams) => getList(ENDPOINTS.LIST_GET_PROGRAMS, params)
export const getBlogPostList = (params?: IParams) => getList(ENDPOINTS.LIST_GET_BLOG_POSTS, params)
export const getBlogCategoriesList = (params?: IParams) => getList(ENDPOINTS.LIST_GET_BLOG_CATEGORIES, params)
export const getPreferenceList = (params?: IParams) => getList(ENDPOINTS.LIST_GET_PREFFERENCES, params)
export const getEshopProductList = (params?: IParams) => getList(ENDPOINTS.LIST_GET_ESHOP_PRODUCTS, params)
export const getVideosList = (params?: IParams) => getList(ENDPOINTS.LIST_GET_VIDEOS, params)
export const getGiftProductsList = (params?: IParams) => getList(ENDPOINTS.LIST_GET_GIFT_PRODUCTS, params)
